import * as React from 'react';
import * as styles from './integration.module.scss';

import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';

const IntegrationTemplate = ({ pageContext }) => {
  const {
    index,
    id,
    label,
    description: shortDescription,
    primaryColor,
    link,
    help,
    category,
    actions,
    zapier,
    total,
  } = pageContext;

  return (
    <>
      <SEO
        title={`Grow Your ${label} Audience with a Lead Generation Quiz`}
        description={`Supercharge your lead generation with Interact's seamless ${label} integration${
          zapier ? ' powered by Zapier' : ''
        }. Automatically segment and personalize follow-ups based on quiz results and responses. Boost conversions and streamline your marketing funnel today.`}
      />
      <Header btnTheme="primary" />
      <main className={styles.integration}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className={styles.logos}>
                <div className={styles.logoContainer}>
                  <div className={styles.logo} style={{ backgroundPosition: `0 -1280px` }}></div>
                </div>
                <div className={styles.logoContainer}>
                  <div
                    className={styles.logo}
                    style={{ backgroundPosition: `0 ${-64 * index}px` }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 text-center">
              <h1 className="heading-xl color-black">Interact + {label}</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground">{shortDescription}</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: `8px` }}>
                {link ? (
                  <Button theme="secondary" type="external" size="large" href={link}>
                    Learn more
                  </Button>
                ) : null}
                {help ? (
                  <Button theme="outline" type="external" size="large" href={help}>
                    Get support
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey" className={styles.dots} style={{ padding: `3rem 0` }}>
          <div className="row no-gutters justify-content-center align-items-center">
            <div className="col-lg-5 col-xl-4">
              <div className={styles.action}>
                <div className={styles.logoIcon} style={{ backgroundColor: `#7371fc14` }}>
                  <div className={styles.logo} style={{ backgroundPosition: `0 -800px` }}></div>
                </div>
                <div>
                  <p className="text-body color-black">
                    <strong>New form submission</strong>
                  </p>
                  <p className="text-body color-muted-foreground" style={{ fontSize: `14px` }}>
                    User submits quiz opt-in form
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div
                className={styles.emailGraphic}
                style={{ '--integration-color': `${zapier ? '#ff4a07' : primaryColor}` }}>
                <div className={styles.emailIcon}>
                  <FontAwesomeIcon icon={faBolt} />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className={styles.actions}>
                {actions.map(
                  ({ id: actionId, label: actionLabel, description: actionDescription }) => {
                    return (
                      <div key={actionId} className={styles.action}>
                        <div
                          className={styles.logoIcon}
                          style={{ backgroundColor: `${zapier ? '#ff4a07' : primaryColor}14` }}>
                          <div
                            className={styles.logo}
                            style={{
                              backgroundPosition: `0 ${zapier ? -40 * (total - 1) : -40 * index}px`,
                            }}></div>
                        </div>
                        <div>
                          <p className="text-body color-black">
                            <strong>{actionLabel}</strong>
                          </p>
                          {!zapier ? (
                            <p
                              className="text-body color-muted-foreground"
                              style={{ fontSize: `14px` }}>
                              {actionDescription}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h3 className="heading-xl color-black">Connect your Interact forms to {label}</h3>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-stretch"
            style={{ marginTop: '3rem' }}>
            <div className="col-lg-4">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Create</p>
                <p className="heading-md">Make a quiz with Interact AI</p>
                <p className="text-body color-muted-foreground">
                  Use our AI quiz maker to create a beautiful quiz for your brand in one minute.
                </p>
                <p className="text-body color-muted-foreground">
                  Enable lead generation by adding an opt-in form to your quiz. Edit the form to
                  match your brand.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Connect</p>
                <p className="heading-md">Connect your {zapier ? 'Zapier' : label} account</p>
                <p className="text-body color-muted-foreground">
                  Seamlessly integrate your quiz to your existing {label} account through our robust{' '}
                  {zapier ? 'Zapier' : 'direct'} integration.
                </p>
                <p className="text-body color-muted-foreground">
                  Our streamlined process is complemented by a comprehensive step-by-step guide,
                  ensuring a smooth and efficient setup experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Segment</p>
                <p className="heading-md">Map your quiz data</p>
                <p className="text-body color-muted-foreground">
                  Leverage our advanced data mapping capabilities to seamlessly integrate quiz
                  responses with {label} actions and fields.
                </p>
                <p className="text-body color-muted-foreground">
                  This enables precise lead segmentation and personalized automation workflows
                  within your existing account.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-white">Get started connecting with {label}</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Start your 14-day free trial to create a quiz with Interact and connect it to your{' '}
                {label} account.
              </p>
              <Button theme="white" size="large" type="internal" href="/plans/">
                Start free trial
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default IntegrationTemplate;
